import { Injectable } from '@angular/core';
import { Constant } from '@shared/model/constants';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  public setUserFromInfoRequest(channelId: number, userId: number, brandName: string, redirectUrl: string): void {
    sessionStorage.setItem(Constant.ChannelIdKey, channelId.toString());
    sessionStorage.setItem(Constant.UserIdKey, userId.toString());
    sessionStorage.setItem(Constant.RedirectUrlKey, redirectUrl);
    sessionStorage.setItem(Constant.BrandName, brandName);
  }

  public checkUserValid(channelId: number, userId: number): boolean {
    const currentChannelId = sessionStorage.getItem(Constant.ChannelIdKey) ;
    const currentUserId = sessionStorage.getItem(Constant.UserIdKey);
    if (!currentUserId) {
      return true;
    }

    if (+currentUserId !== userId || +currentChannelId !== channelId) {
      return false;
    }

    return true;
  }

  public getRedirectUrl(): string {
    return sessionStorage.getItem(Constant.RedirectUrlKey) ;
  }

  public getChannelBrandName(): string {
    return sessionStorage.getItem(Constant.BrandName) ;
  }
}
