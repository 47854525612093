import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrandConfig } from '@shared/model/brand-config.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BrandService } from '../shared/service/brand.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  public brandLogo = '';
  public brandName = '';

  private destroy$: Subject<void> = new Subject();

  constructor(private brandService: BrandService) {
    this.brandService.brandConfig$.pipe(takeUntil(this.destroy$)).subscribe((brandConfig: BrandConfig | undefined) => {
      if (!brandConfig?.brandName) {
        brandConfig = this.brandService.getConfigFromSessionStorage();
      }

      this.brandName = brandConfig?.brandName;
      this.brandLogo = brandConfig?.brandLogo;
    });
  }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
