<header class="app-header" *ngIf="brandName">
  <div class="app-header__contents simp-padding-right-small">
    <div class="simp-padding-left-small">
      <img src="./../../assets/images/icons/house-icon.svg" alt="homeloan-logo" />
    </div>

    <div class="simp-padding-left-small">
      <h5 class="simp-h5 simp-text--bold app-header__text-color">Your home loan</h5>
    </div>

    <div class="app-header__brand-logo-container">
      <img class="app-header__brand-logo" src="{{ './../../assets/brands/' + brandLogo }}" alt="{{ brandName }}" />
    </div>
  </div>
</header>
