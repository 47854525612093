<div class="app-content content">
  <div class="content-wrapper">
    <div class="container body-content">
      <div class="content-body">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-lg-6 col-md-8 col-12 box-shadow-2 p-0">
            <section class="flexbox-container">
              <div class="card border-grey border-lighten-3 m-0">
                <div class="card-header border-0">
                  <div class="card-title text-center py-3 mb-2" *ngIf="infoRequest">
                    <img src="{{ infoRequest?.brandLogo }}" alt="branding logo" class="img-fluid" />
                  </div>
                  <div *ngIf="state === FormState.Initial">Please wait..</div>
                  <div *ngIf="state === FormState.Done">
                    <div class="row my-5 py-3">
                      <div class="col-12">
                        <fieldset class="form-group position-relative has-icon-left">
                          All done, your account is ready!
                        </fieldset>
                      </div>
                    </div>
                    <div class="my-4">
                      <button class="btn btn-primary btn-block" (click)="login()">
                        <i class="fas fa-lock-open"></i> Proceed to Login
                      </button>
                    </div>
                  </div>
                  <div *ngIf="state === FormState.Finalising">
                    <div class="card-content">
                      <div class="card-body">
                        <form class="form-horizontal" [formGroup]="finaliseForm" (ngSubmit)="onFinalise()">
                          <div class="row">
                            <div class="col-12">
                              <fieldset class="form-group position-relative has-icon-left">
                                Hi {{ infoRequest?.firstName }}, set your password to secure your account
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <fieldset class="form-group position-relative has-icon-left mb-4 pb-2">
                                Your username: {{ infoRequest?.email }}
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <fieldset class="form-group position-relative mb-0">
                                <div>
                                  <simp-password [formControl]="f.password" [minimumStrength]="2"
                                    >Password</simp-password
                                  >
                                </div>
                              </fieldset>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12">
                              <fieldset class="form-group position-relative">
                                <label class="simp-label">Confirm password</label>
                                <input
                                  type="password"
                                  formControlName="confirmPassword"
                                  class="form-control"
                                />
                                <div
                                  *ngIf="f.confirmPassword.errors && f.confirmPassword.dirty && f.confirmPassword.touched"
                                  class="simp-field-error simp-text-small simp-text--red mt-1"
                                >
                                  <div *ngIf="f.confirmPassword.errors.required">Confirm password is required</div>
                                  <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords do not match</div>
                                </div>
                                <div class="help-block font-small-3"></div>
                              </fieldset>
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-12">
                              <button type="submit" class="btn btn-primary btn-block">
                                <i class="fas fa-user"></i> Set password
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
