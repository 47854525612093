import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandService } from '@shared/service/brand.service';
import { UserAccessService } from '@shared/service/user-access-service';
import { AuthService } from '@simpology/authentication';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  public error: boolean;

  constructor(
    private authService: AuthService,
    private userAccessService: UserAccessService,
    private brandService: BrandService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public async ngOnInit(): Promise<void> {
    // check for error
    if (this.route.snapshot.fragment.indexOf('error') >= 0) {
      this.error = true;
      return;
    }

    await this.authService.completeAuthentication();

    const isSameUser = this.userAccessService.checkUserValid(this.authService.channelId, this.authService.id);
    if (!isSameUser || this.authService.permissions.length === 0 || this.authService.permissions[0].id === 0) {
      this.authService.signout({
        extraQueryParams: {
          logOutRedirectUri: this.userAccessService.getRedirectUrl(),
        },
      });
      return;
    }

    const brandName = this.userAccessService.getChannelBrandName();

    this.brandService.setBrand(brandName);
    this.router.navigate([this.authService.returnUrl]);
  }
}
