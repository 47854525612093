import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { ConsumerLoginComponent } from './login/consumer-login.component';
import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';

const routes: Routes = [
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'consumerLogin', component: ConsumerLoginComponent },
  {
    path: '',
    loadChildren: (): any => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'home',
    loadChildren: (): any => import('./features/features.module').then((m) => m.FeaturesModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
