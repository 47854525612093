import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
// import { UnauthorizedComponent } from './component/unauthorized/unauthorized.component';
// import { NotFoundComponent } from './component/notfound/notfound.component';
import { BlockUiTemplateComponent } from './block-ui/block-ui-template.component';
import { JwtInterceptor } from './interceptor/jwt.interceptor';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, NgbModule],
  exports: [],
  declarations: [ConfirmationDialogComponent, BlockUiTemplateComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
})
export class SharedModule {}
