import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpAuthenticationModule } from '@simpology/authentication';
import { SimpologyComponentModule } from '@simpology/client-components';
import { SimpInfoRequestModule } from '@simpology/client-components/info-request';
import { ToastrModule } from 'ngx-toastr';
import { ENV_CONFIG } from 'src/env-config';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { ConsumerLoginComponent } from './login/consumer-login.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, AppHeaderComponent, ConsumerLoginComponent, LayoutComponent],
  imports: [
    NgbModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SimpInfoRequestModule,
    SimpologyComponentModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    SimpAuthenticationModule.forRoot({
      environment: ENV_CONFIG,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
