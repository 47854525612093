import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoRequestApiService } from '@features/inforequest/api/user-inforequest-api.service';
import { UserInfoRequest } from '@features/inforequest/model/user-inforequest.model';
import { CustomValidatorFn } from '@helper/custom.validator';
import { PersonStatus } from '@shared/model/enum.model';
import { UserAccessService } from '@shared/service/user-access-service';
import { AuthService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';

export enum State {
  Initial = 1,
  Finalising = 2,
  Done = 3,
}

@Component({
  selector: 'app-consumer-login',
  templateUrl: './consumer-login.component.html',
  styleUrls: ['./consumer-login.component.scss'],
})
export class ConsumerLoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private infoRequestApiService: UserInfoRequestApiService,
    private authService: AuthService,
    private userAccessService: UserAccessService,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder
  ) {}

  private channelId: string;
  private appInfoRequestId: string;
  private key: string;
  public finaliseForm: UntypedFormGroup;
  public state: State = State.Initial;
  public FormState = State;
  public submitted = false;
  public infoRequest: UserInfoRequest;

  public ngOnInit(): void {
    this.channelId = this.route.snapshot.queryParams['channelId'];
    this.appInfoRequestId = this.route.snapshot.queryParams['appInfoRequestId'];
    this.key = this.route.snapshot.queryParams['key'];

    this.finaliseForm = this.formBuilder.group(
      {
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: [CustomValidatorFn.MustMatch('password', 'confirmPassword')],
      }
    );

    this.getInfoRequest();
  }

  public getInfoRequest(): void {
    if (!this.channelId || !this.appInfoRequestId || !this.key) {
      this.toastr.error('No such information request', 'Error');
      return;
    }

    this.infoRequestApiService.getInfoRequest(this.channelId, this.appInfoRequestId, this.key).subscribe(
      (infoRequest) => {
        this.infoRequest = infoRequest;
        if (infoRequest.personStatusEnum === PersonStatus.PreRegistered) {
          this.state = State.Finalising;
        } else {
          this.signInWithCurrentRequest(this.infoRequest);
        }
      },
      (error) => {
        this.toastr.error('No such information request', 'Error');
      }
    );
  }

  public login(): void {
    if (!this.channelId || !this.appInfoRequestId || !this.key) {
      this.toastr.error('No such information request', 'Error');
      return;
    }

    this.infoRequestApiService.getInfoRequest(this.channelId, this.appInfoRequestId, this.key).subscribe(
      (infoRequest) => {
        this.signInWithCurrentRequest(infoRequest);
      },
      (error) => {
        this.toastr.error('No such information request', 'Error');
      }
    );
  }

  private signInWithCurrentRequest(infoRequest: UserInfoRequest): void {
    const currentUrl = this.router.url.substring(1);
    this.userAccessService.setUserFromInfoRequest(
      infoRequest.channelId,
      infoRequest.userId,
      infoRequest.brandName,
      currentUrl
    );
    this.authService.returnUrl = `/home/inforequest/${this.appInfoRequestId}`;

    this.authService.login({
      extraQueryParams: {
        consumer: true,
        userId: infoRequest.userId,
        channelId: infoRequest.channelId,
        reloginUrl: currentUrl,
        channelName: infoRequest.channelName,
        brandLogo: infoRequest.brandLogo || '',
      },
    });
  }

  get f(): any {
    return this.finaliseForm.controls;
  }

  public onFinalise(): void {
    this.submitted = true;
    if (this.finaliseForm.invalid) {
      return;
    }
    this.infoRequestApiService
      .finaliseUser({
        channelId: Number(this.channelId),
        appInfoRequestId: Number(this.appInfoRequestId),
        key: this.key,
        password: this.f.password.value,
      })
      .subscribe(() => {
        this.state = State.Done;
      });
  }
}
