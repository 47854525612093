import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { BaseApiService } from '@simpology/authentication';

import { Router } from '@angular/router';
import { ENV_CONFIG } from 'src/env-config';
import { InfoRequestPersonFinalisationModel } from '../model/inforequest.model';
import { UserInfoRequest } from '../model/user-inforequest.model';

@Injectable({ providedIn: 'root' })
export class UserInfoRequestApiService extends BaseApiService<UserInfoRequest> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('UserInfoRequest');
  }

  public getInfoRequest(channelId: string, appInfoRequestId: string, key: string): Observable<UserInfoRequest> {
    return this.getByQueryString(`?channelId=${channelId}&appInfoRequestId=${appInfoRequestId}&key=${key}`);
  }

  public finaliseUser(item: InfoRequestPersonFinalisationModel): Observable<void> {
    return this.postCustom('FinaliseUser', item);
  }
}
