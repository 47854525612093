export class Constant {
  public static readonly NewId = -1;
  public static readonly AppHeaderHeight = 56;
  public static readonly AppFooterHeight = 56;
  public static readonly BrandName = 'simp:brandName';
  public static readonly BrandConfigKey = 'simp:brandConfig';
  public static readonly UserIdKey = 'simp:userId';
  public static readonly RedirectUrlKey = 'simp:redirectUrl';
  public static readonly ChannelIdKey = 'simp:channelId';
}
