<!-- <app-navigation *ngIf="authenticated()"></app-navigation> -->

<!-- This is just to a placeholder for version stamping. We need this for cinsistency in the build process
If we decide we need it for consumer and how we implement it, this will change
  <td>master.0.0.24</td>
-->
<app-header></app-header>

<footer id="footer" class="footer fixed-bottom footer-dark navbar-border navbar-shadow">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
    <span class="float-md-left d-block d-md-inline-block copyright"
      >Powered by
      <a class="grey darken-2" href="https://www.simpology.com.au" target="_blank"
        ><b><span class="simpology"> Simpology Pty Ltd</span></b></a
      >
      &copy; {{ copyright }}</span
    ><span class="float-md-right d-none d-lg-block"> </span>
  </p>
</footer>

