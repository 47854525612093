interface EnumMapper {
  enumType: any;
  mapping: Map<number, string>;
}

export enum InfoRequestStatus {
  New = 1,
  Open = 2,
  Replied = 3,
  Declined = 4,
  Complete = 5,
  Cancelled = 6,
  Suspended = 7,
}

export enum PersonStatus {
  Active = 1,
  Inactive = 2,
  PreRegistered = 3,
  New = 4,
}

export const InfoRequestStatusLabel = new Map<number, string>([
  [InfoRequestStatus.New, 'New'],
  [InfoRequestStatus.Open, 'Open'],
  [InfoRequestStatus.Replied, 'Replied'],
  [InfoRequestStatus.Declined, 'Declined'],
  [InfoRequestStatus.Complete, 'Complete'],
  [InfoRequestStatus.Cancelled, 'Cancelled'],
  [InfoRequestStatus.Suspended, 'Suspended'],
]);

export enum InfoRequestType {
  Information = 1,
  DocumentUpload = 2,
}

export const InfoRequestTypeLabel = new Map<number, string>([
  [InfoRequestType.Information, 'Information'],
  [InfoRequestType.DocumentUpload, 'DocumentUpload'],
]);

export enum ApplicationParticipantSubType {
  Administrator = 1,
  Broker = 2,
  TeamLead = 3,
  CreditAssessor = 4,
  Consumer = 5,
  LoanOfficer = 6,
  MobileLender = 7,
  Manager = 8,
  SeniorAssessor = 9,
  Lawyer = 10,
  Accountant = 11,
  Advisor = 12,
  Assessor = 13,
  Valuer = 14,
  ProductManager = 15,
  PrimaryApplicant = 101,
  CoApplicant = 102,
  BusinessDevelopmentManager = 20,
  BrokerAdministration = 21,
  ProductTeam = 22,
  ProjectTeam = 23,
  DistributionTeam = 24,
  MortgageServicesTeamLeader = 25,
  MortgageServicesTeamMember = 26,
  QualityAssuranceTeamMember = 27,
  SimpologyAdmin = 28,
  MortgageInsurer = 29,
  PackagingOfficer = 30,
  ReadOnly = 31,
  Translator = 32,
  RetentionOfficer = 33,
  BrokerSupportOfficer = 34,
  SupportStaff = 35,
  BrokerageManager = 36,
  LoanSpecialist = 37,
  SalesSupportOfficer = 38,
  UnknownEntity = 39,
  Solicitor = 40,
  CreditTeamLead = 41,
  Assistant = 42,
  LoanWriterAdminAssistant = 43,
  Supervisor = 44,
  SeniorManager = 45,
  InternalLoanWriter = 46,
  FraudManager = 47,
  Conveyencer = 48,
  ServiceabilityManager = 49,
  PASAASManager = 50,
  SystemManager = 51,
  UserManagement = 52,
  Franchise = 53,
  BusinessManager = 54,
  AccreditedAdministrator = 55,
  FranchiseAdministrator = 56,
  LoanWriter = 57,
  Partner = 58,
  LMISetupAdmin = 59,
  TechSupport = 60,
  FranchiseOwner = 61,
  MLManagement = 62,
  AdminAssistant = 63,
  BusinessAdministrator = 64,
  BusinessAdministratorFirstParty = 65,
  BusinessAdministratorThirdParty = 66,
  SubmittingOfficer = 67,
  LendingSpecialist = 68,
  RegionalManager = 69,
}

export const ApplicationParticipantSubTypeLabel = new Map<number, string>([
  [ApplicationParticipantSubType.Administrator, 'Administrator'],
  [ApplicationParticipantSubType.Broker, 'Broker'],
  [ApplicationParticipantSubType.TeamLead, 'Team Lead'],
  [ApplicationParticipantSubType.CreditAssessor, 'Credit Assessor'],
  [ApplicationParticipantSubType.Consumer, 'Consumer'],
  [ApplicationParticipantSubType.LoanOfficer, 'Loan Officer'],
  [ApplicationParticipantSubType.MobileLender, 'Mobile Lender'],
  [ApplicationParticipantSubType.Manager, 'Manager'],
  [ApplicationParticipantSubType.SeniorAssessor, 'Senior Assessor'],
  [ApplicationParticipantSubType.Lawyer, 'Lawyer'],
  [ApplicationParticipantSubType.Accountant, 'Accountant'],
  [ApplicationParticipantSubType.Advisor, 'Advisor'],
  [ApplicationParticipantSubType.Assessor, 'Assessor'],
  [ApplicationParticipantSubType.Valuer, 'Valuer'],
  [ApplicationParticipantSubType.ProductManager, 'Product Manager'],
  [ApplicationParticipantSubType.PrimaryApplicant, 'Primary applicant'],
  [ApplicationParticipantSubType.CoApplicant, 'Co-applicant'],
  [ApplicationParticipantSubType.BusinessDevelopmentManager, 'Business Development Manager'],
  [ApplicationParticipantSubType.BrokerAdministration, 'Broker Administration'],
  [ApplicationParticipantSubType.ProductTeam, 'Product Team'],
  [ApplicationParticipantSubType.ProjectTeam, 'Project Team'],
  [ApplicationParticipantSubType.DistributionTeam, 'Distribution Team'],
  [ApplicationParticipantSubType.MortgageServicesTeamLeader, 'Mortgage Services Team Leader'],
  [ApplicationParticipantSubType.MortgageServicesTeamMember, 'Mortgage Services Team Member'],
  [ApplicationParticipantSubType.QualityAssuranceTeamMember, 'Quality Assurance Team Member'],
  [ApplicationParticipantSubType.SimpologyAdmin, 'Simpology Admin'],
  [ApplicationParticipantSubType.MortgageInsurer, 'Mortgage Insurer'],
  [ApplicationParticipantSubType.PackagingOfficer, 'Packaging Officer'],
  [ApplicationParticipantSubType.ReadOnly, 'Read Only'],
  [ApplicationParticipantSubType.Translator, 'Translator'],
  [ApplicationParticipantSubType.RetentionOfficer, 'Retention Officer'],
  [ApplicationParticipantSubType.BrokerSupportOfficer, 'Broker Support Officer'],
  [ApplicationParticipantSubType.SupportStaff, 'Support Staff'],
  [ApplicationParticipantSubType.BrokerageManager, 'Brokerage Manager'],
  [ApplicationParticipantSubType.LoanSpecialist, 'Loan Specialist'],
  [ApplicationParticipantSubType.SalesSupportOfficer, 'Sales Support Officer'],
  [ApplicationParticipantSubType.UnknownEntity, 'Unknown Entity'],
  [ApplicationParticipantSubType.Solicitor, 'Solicitor'],
  [ApplicationParticipantSubType.CreditTeamLead, 'Credit Team Lead'],
  [ApplicationParticipantSubType.Assistant, 'Assistant'],
  [ApplicationParticipantSubType.LoanWriterAdminAssistant, 'Loan Writer Admin Assistant'],
  [ApplicationParticipantSubType.Supervisor, 'Supervisor'],
  [ApplicationParticipantSubType.SeniorManager, 'Senior Manager'],
  [ApplicationParticipantSubType.InternalLoanWriter, 'Internal Loan Writer'],
  [ApplicationParticipantSubType.FraudManager, 'Fraud Manager'],
  [ApplicationParticipantSubType.Conveyencer, 'Conveyencer'],
  [ApplicationParticipantSubType.ServiceabilityManager, 'Serviceability Manager'],
  [ApplicationParticipantSubType.PASAASManager, 'PASAAS Manager'],
  [ApplicationParticipantSubType.SystemManager, 'System Manager'],
  [ApplicationParticipantSubType.UserManagement, 'User Management'],
  [ApplicationParticipantSubType.Franchise, 'Franchise'],
  [ApplicationParticipantSubType.BusinessManager, 'Business Manager'],
  [ApplicationParticipantSubType.AccreditedAdministrator, 'Accredited Administrator'],
  [ApplicationParticipantSubType.FranchiseAdministrator, 'Franchise Administrator'],
  [ApplicationParticipantSubType.LoanWriter, 'Loan Writer'],
  [ApplicationParticipantSubType.Partner, 'Partner'],
  [ApplicationParticipantSubType.LMISetupAdmin, 'LMI Setup Admin'],
  [ApplicationParticipantSubType.SupportStaff, 'Tech Support'],
  [ApplicationParticipantSubType.FranchiseOwner, 'Franchise Owner'],
  [ApplicationParticipantSubType.MLManagement, 'ML Management'],
  [ApplicationParticipantSubType.AdminAssistant, 'Admin Assistant'],
  [ApplicationParticipantSubType.BusinessAdministrator, 'Business Administrator'],
  [ApplicationParticipantSubType.BusinessAdministratorFirstParty, 'Business Administrator - First Party'],
  [ApplicationParticipantSubType.BusinessAdministratorThirdParty, 'Business Administrator - Third Party'],
  [ApplicationParticipantSubType.SubmittingOfficer, 'Submitting Officer'],
  [ApplicationParticipantSubType.LendingSpecialist, 'Lending Specialist'],
  [ApplicationParticipantSubType.RegionalManager, 'Regional Manager'],
]);

export const EnumLabel: EnumMapper[] = [
  { enumType: InfoRequestStatus, mapping: InfoRequestStatusLabel },
  { enumType: InfoRequestType, mapping: InfoRequestTypeLabel },
  {
    enumType: ApplicationParticipantSubType,
    mapping: ApplicationParticipantSubTypeLabel,
  },
];
