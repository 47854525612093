import { UntypedFormGroup, UntypedFormControl, ValidatorFn } from '@angular/forms';

export class CustomValidatorFn {
  public static MustMatch = (controlName: string, matchingControlName: string): any => (formGroup: UntypedFormGroup): any => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };

  public static PhoneNumber = (control: UntypedFormControl): any => {
    if (!control.value) {
      return null;
    }
    // eslint-disable-next-line max-len
    const pattern = /^(\+\d{2}[ \-]{0,1}){0,1}(((\({0,1}[ \-]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ \-]*(\d{4}[ \-]{0,1}\d{4}))|(1[ \-]{0,1}(300|800|900|902)[ \-]{0,1}((\d{6})|(\d{3}[ \-]{0,1}\d{3})))|(13[ \-]{0,1}([\d \-]{5})|((\({0,1}[ \-]{0,1})0{0,1}\){0,1}4{1}[\d \-]{8,10})))$/;
    if (pattern.test(control.value)) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  };

  public static EmailAddress = (control: UntypedFormControl): any => {
    if (!control.value) {
      return null;
    }
    // Credit: https://github.com/FluentValidation/FluentValidation/blob/10.x/src/FluentValidation/Validators/EmailValidator.cs
    // eslint-disable-next-line max-len
    const emailPattern = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
    if (emailPattern.test(control.value)) {
      return null;
    } else {
      return { email: true };
    }
  };

  public static PersonName = (control: UntypedFormControl): any => {
    if (!control.value) {
      return null;
    }
    // eslint-disable-next-line max-len
    const pattern = /^[A-Za-z][A-Za-z'-]+([ A-Za-z][A-Za-z'-]+)*/;
    if (pattern.test(control.value)) {
      return null;
    } else {
      return { invalidName: true };
    }
  };

  public static NonEmpty = (control: UntypedFormControl): any => {
    if (!control.value) {
      return null;
    }
    const pattern = /[^\s-]/;
    if (pattern.test(control.value)) {
      return null;
    } else {
      return { required: true };
    }
  };

  public static Website = (control: UntypedFormControl): any => {
    if (!control.value) {
      return null;
    }
    // eslint-disable-next-line max-len
    const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    if (pattern.test(control.value)) {
      return null;
    } else {
      return { invalidUrl: true };
    }
  };

  public static conditionalValidator = (
    conditionControl: string,
    controlToCheck: string,
    validator: ValidatorFn
  ): any => (formGroup: UntypedFormGroup): any => {
    const condition = formGroup.controls[conditionControl];
    const control = formGroup.controls[controlToCheck];

    if (condition.value) {
      control.setErrors(validator(control));
    } else {
      control.setErrors(null);
    }
  };

  public static conditionalValueValidator = (
    conditionControl: string,
    controlToCheck: string,
    conditionalValue: any,
    validator: ValidatorFn
  ): any => (formGroup: UntypedFormGroup): any => {
    const condition = formGroup.controls[conditionControl];
    const control = formGroup.controls[controlToCheck];

    if (control.errors) {
      return;
    }

    if (condition.value === conditionalValue) {
      control.setErrors(validator(control));
    } else {
      control.setErrors(null);
    }
  };

  public static conditionalMultiValueValidator = (
    conditionControl: string,
    controlToCheck: string,
    conditionalValues: any[],
    validator: ValidatorFn
  ): any => (formGroup: UntypedFormGroup): any => {
    const condition = formGroup.controls[conditionControl];
    const control = formGroup.controls[controlToCheck];

    if (control.errors) {
      return;
    }

    if (conditionalValues.some((v) => v === condition.value)) {
      control.setErrors(validator(control));
    } else {
      control.setErrors(null);
    }
  };

  public static conditionalCompareForGreaterValueValidator = (
    conditionControl: string,
    controlToCheck: string,
    controlToCompareCheck: string,
    conditionalValue: any
  ): any => (formGroup: UntypedFormGroup): any => {
    const condition = formGroup.controls[conditionControl];
    const control = formGroup.controls[controlToCheck];
    const controlToCompare = formGroup.controls[controlToCompareCheck];

    if (control.errors && !control.errors.greater) {
      return;
    }

    if (condition.value === conditionalValue && control.value <= controlToCompare.value) {
      control.setErrors({ greater: true });
    } else {
      control.setErrors(null);
    }
  };

  public static conditionalCompareForGreaterValueIfExistsValidator = (
    conditionControl: string,
    controlToCheck: string,
    controlToCompareCheck: string,
    conditionalValue: any
  ): any => (formGroup: UntypedFormGroup): any => {
    const condition = formGroup.controls[conditionControl];
    const control = formGroup.controls[controlToCheck];
    const controlToCompare = formGroup.controls[controlToCompareCheck];

    if (control.errors && !control.errors.greater) {
      return;
    }

    if (
      condition.value === conditionalValue &&
      control.value &&
      controlToCompare.value &&
      control.value <= controlToCompare.value
    ) {
      control.setErrors({ greater: true });
    } else {
      control.setErrors(null);
    }
  };
}
