import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BrandConfig } from '@shared/model/brand-config.model';
import { Constant } from '@shared/model/constants';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';

@Injectable({ providedIn: 'root' })
export class BrandService extends BaseApiService<any> {
  public currentBrandName$: Observable<string>;
  public brandConfig$: Observable<BrandConfig>;

  private currentBrandNameValue = '';
  private currentBrandName: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentBrandNameValue);
  private brandConfig: BehaviorSubject<BrandConfig> = new BehaviorSubject<BrandConfig>({} as BrandConfig);

  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    // this.setLadmRoute('Brand');
    this.currentBrandName$ = this.currentBrandName.asObservable();
    this.brandConfig$ = this.brandConfig.asObservable();
  }

  public setBrand(brandName: string): void {
    this.currentBrandNameValue = brandName.toLowerCase();

    this.http.get<BrandConfig>(`assets/brands/${this.currentBrandNameValue}/config.json`).subscribe(
      (brandConfig: BrandConfig) => {
        sessionStorage.setItem(Constant.BrandConfigKey, JSON.stringify(brandConfig));
        this.brandConfig.next(brandConfig);

        this.setBrandCssProperties(brandConfig);
      },
      (error) => {
        this.setDefaultBrandConfig();
      }
    );

    //TODO: favicon
    // document
    //   .getElementById('favIcon')
    //   ?.setAttribute('href', `assets/brands/${this.currentBrandNameValue}/images/favicon.png`);
  }

  private setBrandCssProperties(brandConfig: BrandConfig) {
    const documentRoot = document.documentElement;
    Object.keys(brandConfig.cssVars).forEach((cssVar) => {
      documentRoot.style.setProperty(cssVar, brandConfig.cssVars[cssVar]);
    });
  }

  private setDefaultBrandConfig(): void {
    this.http.get<BrandConfig>(`assets/brands/default/config.json`).subscribe((brandConfig: any) => {
      sessionStorage.setItem(Constant.BrandConfigKey, '');
      this.brandConfig.next({} as BrandConfig);
      this.setBrandCssProperties(brandConfig);
    });
  }

  public getConfigFromSessionStorage(): BrandConfig | undefined {
    const brandConfigString = sessionStorage.getItem(Constant.BrandConfigKey);
    if (brandConfigString && brandConfigString !== 'undefined') {
      const currentBrandConfig = JSON.parse(brandConfigString) as BrandConfig;
      if (currentBrandConfig?.brandName) {
        this.setBrandCssProperties(currentBrandConfig);
        return currentBrandConfig;
      }
    }

    return undefined;
  }
}
